import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Breadcrumb from "../../../components/breadcrumb"
import Checkbox from "../../../components/checkbox"
import Constrain from "../../../components/constrain"
import Paragraph from "../../../components/paragraph"
import Heading from "../../../components/heading"
import Image from "../../../components/image"
import Layout from "../../../layouts/default"
import PageTitle from "../../../components/page-title"
import QuotePrimary from "../../../components/quote-primary"
import Poem from "../../../components/poem"
import Grid from "../../../components/grid"
import Seo from "../../../components/seo"
import Stack from "../../../components/stack"
import StepsBox from "../../../components/steps-box"
import StepsHeading from "../../../components/steps-heading"
import Text from "../../../components/text"
import TextLink from "../../../components/textlink"
import VideoPlayer from "../../../components/video-player"
import Inline from "../../../components/inline"

const WorkshopKalenderPage = () => {
  const data = useStaticQuery(graphql`
    query WorkshopMaerzPageQuery {
      farbpalette: file(
        relativePath: { eq: "workshops/kalender/maerz/farbpalette.jpg" }
      ) {
        ...largeImage
      }
      poster01: file(
        relativePath: { eq: "workshops/kalender/maerz/poster-01.png" }
      ) {
        ...largeImage
      }
      poster02: file(
        relativePath: { eq: "workshops/kalender/maerz/poster-02.png" }
      ) {
        ...largeImage
      }
      poster03: file(
        relativePath: { eq: "workshops/kalender/maerz/poster-03.png" }
      ) {
        ...largeImage
      }
      beispiel01: file(
        relativePath: { eq: "workshops/kalender/maerz/beispiel-1.jpg" }
      ) {
        ...largeImage
      }
      beispiel02: file(
        relativePath: { eq: "workshops/kalender/maerz/beispiel-2.jpg" }
      ) {
        ...largeImage
      }
      beispiel03: file(
        relativePath: { eq: "workshops/kalender/maerz/beispiel-3.jpg" }
      ) {
        ...largeImage
      }
      ogImage: file(relativePath: { eq: "og_images/kalender.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1200, height: 600, layout: FIXED)
        }
      }
    }
  `)

  return (
    <Layout backdrop="workshop-kalender-maerz">
      <Seo
        title="März"
        description="Im Monat März wird’s bunt! Wir sammeln Farben und zeigen dir, wie man aus Wörtern und Buchstaben Bilder erschaffen kann. Bist du schon neugierig?"
        image={data.ogImage.childImageSharp.gatsbyImageData}
      />
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Digital",
              link: "/digital",
            },
            {
              title: "Workshops",
              link: "/workshops",
            },
            {
              title: "Wörter und Bilder",
              link: "/workshops/kalender",
            },
            {
              title: "März",
              link: "/workshops/kalender/maerz",
            },
          ]}
        />
        <Stack space={[24, 24, 32]}>
          <Stack>
            <PageTitle>März</PageTitle>
            <Paragraph>
              Im Monat März wird’s bunt! Die Blumen kriechen aus der Erde und
              langsam aber sicher erwacht alles aus dem Winterschlaf. Für die
              Gestaltung deiner Kalenderseite sammeln wir Dinge des Frühlings
              und überlegen uns Worte, mit denen man ihre Farben beschreiben
              kann. Dabei zeigen wir dir, wie man aus Wörtern und Buchstaben
              Bilder erschaffen kann. Bist du schon neugierig?
            </Paragraph>
          </Stack>

          <Stack>
            <Constrain>
              <Stack space={6}>
                <StepsHeading as="h2" subtitle="Schritt 1">
                  Die Farben des Jahres
                </StepsHeading>
                <Paragraph>
                  Jede Jahreszeit hat ihre eigenen Farben und wenn du ganz genau
                  hinsiehst, bemerkst du, wie unglaublich facettenreich das
                  Farbspektrum der Natur ist. Der Dichter Friedrich Hölderlin
                  war ein aufmerksamer Beobachter der Natur. Mehr dazu erfährst
                  du in diesem Video.
                </Paragraph>
              </Stack>
            </Constrain>
            <VideoPlayer
              src="workshops/kalender/maerz/video-01"
              poster={data.poster01}
            />
            <Constrain>
              <Stack>
                <Paragraph>
                  Hölderlin hat den Beginn des Frühlings zum Beispiel einmal so
                  beschrieben:
                </Paragraph>
                <QuotePrimary author="Friedrich Hölderlin: ›Die Liebe‹">
                  <Poem>
                    <p>Doch, wie immer das Jahr kalt und gesanglos ist</p>
                    <p>Zur beschiedenen Zeit, aber aus weißem Feld</p>
                    <p>Grüne Halme doch sprossen.</p>
                    <p>Oft ein einsamer Vogel singt.</p>
                  </Poem>
                </QuotePrimary>
              </Stack>
            </Constrain>
          </Stack>
          <Stack>
            <Constrain>
              <Stack space={6}>
                <StepsHeading as="h2" subtitle="Schritt 2">
                  Materialien für deine Kalenderseite
                </StepsHeading>
                <Paragraph>
                  Bist du schon gespannt, was wir uns dazu ausgedacht haben?
                  Hier erfährst du, welche Materialien du dafür benötigst.
                </Paragraph>
              </Stack>
            </Constrain>
            <VideoPlayer
              src="workshops/kalender/maerz/video-02"
              poster={data.poster02}
            />
            <Constrain>
              <Stack space={6}>
                <Heading as="p" level={5}>
                  Deine Checkliste
                </Heading>
                <Stack space={4}>
                  <Checkbox space={3} id="checklist-1">
                    <Text as="span" size={4}>
                      Viele bunte Stifte! Gerne auch Wasserfarben oder
                      wasservermalbare Stifte, falls du welche hast
                    </Text>
                  </Checkbox>
                  <Checkbox space={3} id="checklist-2">
                    <Text as="span" size={4}>
                      Eine Schere und einen Klebestift
                    </Text>
                  </Checkbox>
                  <Checkbox space={3} id="checklist-3">
                    <Text as="span" size={4}>
                      Ein weißes Blatt Papier
                    </Text>
                  </Checkbox>
                </Stack>
              </Stack>
            </Constrain>
          </Stack>
          <Stack>
            <Constrain>
              <Stack space={6}>
                <StepsHeading as="h2" subtitle="Schritt 3">
                  Mit Worten und Farben malen – so geht das...
                </StepsHeading>
                <Paragraph>
                  Sind dir schon ein paar Dinge und Farben eingefallen? Dann
                  geht es jetzt richtig los. Wir erklären dir, wie du damit
                  deine Kalenderseite gestalten kannst.
                </Paragraph>
              </Stack>
            </Constrain>
            <VideoPlayer
              src="workshops/kalender/maerz/video-03"
              poster={data.poster03}
            />

            <Constrain>
              <Stack space={6}>
                <Heading as="p" level={5}>
                  Deine Aufgabe Schritt für Schritt:
                </Heading>
                <Stack space={6}>
                  <StepsBox step={1}>
                    <Paragraph>
                      Überlege, welche Dinge dir zum Monat März einfallen.{" "}
                    </Paragraph>
                  </StepsBox>
                  <StepsBox step={2}>
                    <Stack space={3}>
                      <Paragraph>
                        Stelle sie dir ganz genau vor und überlege, welche Farbe
                        sie haben. Sind sie gras-grün oder eher altglas-grün,
                        schlumpf-blau, freibadbecken-blau, mohnblumen-rot,
                        feuermelder-rot oder doch eher eidottergelb? Überlege,
                        mit was man ihre Farbe vergleichen könnte und denke dir
                        ein schönes Wort dafür aus. Vielleicht hilft dir auch
                        dieser Farbfächer dabei.
                      </Paragraph>
                      <Image
                        image={data.farbpalette.childImageSharp.gatsbyImageData}
                        alt="Ein Bild der Farbpalette"
                      />
                    </Stack>
                  </StepsBox>
                  <StepsBox step={3}>
                    <Paragraph>
                      Zeichne die Konturen dieser Dinge mit einem Bleistift auf
                      ein weißes Blatt Papier. Du kannst auch gleich einen Stift
                      in der passenden Farbe verwenden.
                    </Paragraph>
                  </StepsBox>
                  <StepsBox step={4}>
                    <Paragraph>
                      Anschließend schreibst du das Farbwort, das dir zu deinem
                      Gegenstand eingefallen ist, mit einem passenden farbigen
                      Stift so hinein, dass sich daraus ein Bild ergibt.
                    </Paragraph>
                  </StepsBox>
                  <StepsBox step={5}>
                    <Paragraph>
                      Du kannst auch mit Wasserfarben malen und das Farbwort
                      neben deine Zeichnung schreiben.
                    </Paragraph>
                  </StepsBox>
                  <StepsBox step={6}>
                    <Paragraph>
                      Deine Wort-Bilder und Begriffe kannst du anschließend
                      ausschneiden und zu einer Collage auf deiner Kalenderseite
                      zusammenfügen.
                    </Paragraph>
                  </StepsBox>
                </Stack>
              </Stack>
            </Constrain>
          </Stack>

          <Stack>
            <Constrain>
              <Heading as="h2" level={4}>
                So kann deine Kalenderseite aussehen:
              </Heading>
            </Constrain>
            <Grid columns={[1, 2]} space={3} alignX="center">
              <Image
                image={data.beispiel02.childImageSharp.gatsbyImageData}
                alt="Detailbild mit Prinzessinen-Rosa"
              />
              <Image
                image={data.beispiel03.childImageSharp.gatsbyImageData}
                alt="Zitronenfalter-Gelb auf schwarzem Hintergrund"
              />
              <Image
                image={data.beispiel01.childImageSharp.gatsbyImageData}
                alt="Der fertige Kalender"
              />
            </Grid>
            <Constrain>
              <Paragraph>
                Wir freuen uns über ein Bild von deiner Kalenderseite! Lass dir
                von einem Erwachsenen helfen und schicke uns ein Foto an{" "}
                <TextLink href="mailto:hoelderlinturm@tuebingen.de">
                  hoelderlinturm@tuebingen.de
                </TextLink>
              </Paragraph>
            </Constrain>
          </Stack>

          <Constrain>
            <Stack>
              <Stack space={6}>
                <Heading as="h2" level={4}>
                  Hölderlin und die Farben
                </Heading>
                <Paragraph>
                  Besonders häufig kommt in Hölderlins Gedichten die Farbe Gold
                  vor. Weitere Farbbeschreibungen aus Hölderlins Gedichten...
                </Paragraph>
              </Stack>
              <Inline alignX="center">
                <Text sans={true} size={[2, 3, 4]}>
                  weißblühend
                </Text>
                <Text sans={true} size={[2, 3, 4]}>
                  schneeweiß
                </Text>
                <Text sans={true} size={[2, 3, 4]}>
                  morgenrötlich
                </Text>
                <Text sans={true} size={[2, 3, 4]}>
                  abendrötlich
                </Text>
                <Text sans={true} size={[2, 3, 4]}>
                  silbergrau
                </Text>
                <Text sans={true} size={[2, 3, 4]}>
                  Purpurmund
                </Text>
                <Text sans={true} size={[2, 3, 4]}>
                  Purpurwange
                </Text>
                <Text sans={true} size={[2, 3, 4]}>
                  Purpurschein
                </Text>
                <Text sans={true} size={[2, 3, 4]}>
                  Silberlocken
                </Text>
                <Text sans={true} size={[2, 3, 4]}>
                  Silberhaare
                </Text>
                <Text sans={true} size={[2, 3, 4]}>
                  Silbergreise
                </Text>
                <Text sans={true} size={[2, 3, 4]}>
                  Silbertone
                </Text>
                <Text sans={true} size={[2, 3, 4]}>
                  Silbergefäß
                </Text>
                <Text sans={true} size={[2, 3, 4]}>
                  Silberquell
                </Text>
                <Text sans={true} size={[2, 3, 4]}>
                  Silberblüten
                </Text>
                <Text sans={true} size={[2, 3, 4]}>
                  Silberwelle
                </Text>
                <Text sans={true} size={[2, 3, 4]}>
                  Silberwolken
                </Text>
                <Text sans={true} size={[2, 3, 4]}>
                  Silberpappeln
                </Text>
                <Text sans={true} size={[2, 3, 4]}>
                  immergün
                </Text>
                <Text sans={true} size={[2, 3, 4]}>
                  umgrünen
                </Text>
                <Text sans={true} size={[2, 3, 4]}>
                  frischaufgrünend
                </Text>
                <Text sans={true} size={[2, 3, 4]}>
                  übergrünen
                </Text>
                <Text sans={true} size={[2, 3, 4]}>
                  goldgelockt
                </Text>
                <Text sans={true} size={[2, 3, 4]}>
                  Goldgewölk
                </Text>
                <Text sans={true} size={[2, 3, 4]}>
                  goldglänzend
                </Text>
                <Text sans={true} size={[2, 3, 4]}>
                  goldenklingend
                </Text>
                <Text sans={true} size={[2, 3, 4]}>
                  Goldrot
                </Text>
              </Inline>
            </Stack>
          </Constrain>
        </Stack>
      </Stack>
    </Layout>
  )
}

export default WorkshopKalenderPage
